import React, { Suspense } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import Loadable from 'react-loadable';

// import '../../node_modules/font-awesome/scss/font-awesome.scss';

import Loader from './layout/Loader'
import Aux from "../hoc/_Aux";
import ScrollToTop from './layout/ScrollToTop';
// import SignUpIndex from '../Components/Authentication/SignUp';
import { useSelector } from 'react-redux';
import { isMobileOnly } from 'react-device-detect';
import MobileView from '../Components/UI/MobileView';
import CacheInvalidator from './CacheInvalidator/CacheInvalidator';
// import { TramOutlined } from '@material-ui/icons';
// import SignInIndex from '../Components/Authentication/SignIn';
// import ForgetPassword from '../Components/Authentication/SignIn/ForgetPassword';
// import ResetPassword from '../Components/Authentication/SignIn/ResetPassword';


import { lazyRetry } from '../Components/Utils/componentLoader';
import ErrorComponent from 'Components/Error Component/ErrorComponent';
const AdminLayout = Loadable({
  loader: () => import('./layout/AdminLayout'),
  loading: Loader
});

const SignUpIndex = React.lazy(() => lazyRetry(() => import('../Components/Authentication/SignUp'), 3));
const SignInIndex = React.lazy(() => lazyRetry(() => import('../Components/Authentication/SignIn'), 3));
const ResetPassword = React.lazy(() => lazyRetry(() => import('../Components/Authentication/SignIn/ResetPassword'), 3));



// class App extends Component {
const App = () => {

  const isLogin = useSelector((state) => state.login.isLogin);

  if (isMobileOnly) {
    return <MobileView />
  }
  return (
    <CacheInvalidator>
      {({ loading, isLatestVersion, refreshCacheAndReload }) => {
        if (loading) return null;
        if (!isLatestVersion) {
          refreshCacheAndReload();
        }
        // console.log({loading, isLatestVersion, refreshCacheAndReload})
        return (
          <Aux>
            <ScrollToTop>
              <Suspense fallback={<Loader />}>
                <Routes>
                  {/* {!isLogin && menu}  this condition is used for protecting authpages after signing in  */}
                  {!isLogin && <Route path='/auth/signup' element={<SignUpIndex />} />}
                  {!isLogin && <Route path='/auth/signin' element={<SignInIndex forgetPage={false} />} />}
                  {!isLogin && <Route path='/auth/password/reset' element={<SignInIndex forgetPage={true} />} />}
                  {!isLogin && <Route path='/auth/password/reset/confirm/:id' element={<ResetPassword />} />}
                  {!isLogin && <Route path='*' element={<Navigate to='/auth/signin' />} />}
                  {/* {!isLogin && <Route path='*' element={<ErrorPage/>}  />} */}
                  {!isLogin && <Route path="/login-error" element={<ErrorComponent />} />}
                </Routes>
                {isLogin && <AdminLayout />}
                {/* <AdminLayout/> */}
              </Suspense>
            </ScrollToTop>
          </Aux>
        );
      }}
    </CacheInvalidator>


  );
}

export default App;
