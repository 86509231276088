import { createSlice } from "@reduxjs/toolkit";

//Please add your source as unique whenever you want to use this reducer
// and always call remove() whenever your state destroys 
// when ever clear filter or searching always pass limit=10 and offset=0

const initialFilterState = {
  limit: 7,
  offset: 0,
  sort: "",
  status: [],
  search: "",
  order_by:"",
  leader: [],
  author: [],
  sort_by_name: "",
//   sort_by_doj: "",
//   is_active: "",
//   payment_status: "",
//   period_id: [],
//   status_list: [],
//   version_id: null,
//   from_submission_date: "",
//   type_ids:[],
//   to_submission_date: "",
//   type:'',       //for user and admin => pickup story type
//   status:''     //for user => pickup story type
};

const initialChronicleDetail = {
  showEpisode : false,
  title : '',
  leader_name : '',
  profile : '',
  id : null,
  status:''
}

const chronicleFilterSlice = createSlice({
  name: "chroncile-filter",
  initialState: {
    source: "",
    filters: initialFilterState,
    chronicleDetail : initialChronicleDetail,
    clearFilter: false,
  },
  reducers: {
    setFilter(state, action) {
      state.filters = Object.assign(state.filters, action.payload);
      delete state.filters.source;
      delete state.filters.clearFilter;
      state.source = action.payload.source;
      if (action.payload.clearFilter) {
        state.clearFilter = action.payload.clearFilter;
      }
    },
    removeFilter(state, action) {
      state.filters = initialFilterState;
      state.source = action.payload.source;
      state.clearFilter = false;
    },
    removePagination(state, action) {
      const filter = {
        ...state.filters,
        limit: 7,
        offset: 0,
      };
      state.filters = filter;
      state.source = action.payload.source;
      state.clearFilter = false;
    },
    setChronicleDetail(state,action){
      const obj = { 
        showEpisode : true,
        title : action.payload.title,
        leader_name : action.payload.leader_name,
        profile : action.payload.profile,
        id :action.payload.id,
        status:action.payload.status
      }
      state.chronicleDetail= obj;
    },
    removeChronicleData(state,action){
      state.chronicleDetail = initialChronicleDetail
    }
  },
});

export const chronicleFilterAction = chronicleFilterSlice.actions;

export default chronicleFilterSlice;
