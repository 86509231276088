import MobileImage from '../../assets/images/mobileError.jpg'

const MobileView = () =>{
    return <>
        <div style={{width:'100vw',height:'100vh',background:'white',display:'flex',flexDirection:'column',alignItems:'center',justifyContent:'center'}}>
            <img src={MobileImage} alt='error screen'/>
            <div style={{width:'80%', display:'flex',flexDirection:'column',justifyContent:'center', marginTop:'3em'}}>
                <h2 style={{color:'#A61D29',fontSize:'2em',fontWeight:'800',textAlign:'center'}}>Open in Desktop</h2>
                <p style={{textAlign:'center',color:'#6C6C6C',fontSize:'1.4em',fontFamily:'Manrope',fontStyle:'semiBold'}}>Currently we do not support mobile devices for the dashboard. We will let you know when this is available.</p>
            </div>
        </div>
    </>
}

export default MobileView;