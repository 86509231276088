import { createSlice } from "@reduxjs/toolkit";

const AuthorLocalCommentsSlice = createSlice({
    name: 'Author Local Comments',
    initialState: {
        comments: [],
        quillComments: []
    },
    reducers: {
        setComment(state, action) {
            // 
            state.comments = action.payload;
            // 
        },
        setQuillAndComment(state, action) {
            state.quillComments = [...state.quillComments, action.payload];
            // 
        },
        setQuill(state, action) {
            // 
            state.quillComments = action.payload;
            let tempComments = [];
            action.payload.map((comments) => tempComments.push(comments[0]));
            state.comments = tempComments
        },
    }
});

export const AuthorLocalCommentsAction = AuthorLocalCommentsSlice.actions;

export default AuthorLocalCommentsSlice;
