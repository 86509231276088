import { Snackbar } from "@material-ui/core";
import { useEffect, useState } from "react";
import { Alert } from "react-bootstrap";
import { useSelector } from "react-redux";
import store from "../../store";
import { NState } from "../../store/reducer/notification";


export type RootState = ReturnType<typeof store.getState>

export default function NotificationManager(){
   const notification =  useSelector((state:RootState)=> state.notificationReducer);
   const [open,setOpen] = useState(true);
   const [divClass,setClass]= useState("success")
   useEffect(() => {
      if(notification.type === NState.ERROR){
         setClass("danger");
      }
      if(notification.type === NState.SUCCESS){
         setClass("success");
      }
      if(notification.type === NState.PROCESSING){
         setClass("warning");
      }
      setOpen(true);
   } , [notification])
   const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
      if (reason === 'clickaway') {
        return;
      }
  
      setOpen(false);
    };
   return(
    <div className="notification">
      {notification.type !== NState.NULL && <Snackbar 
         anchorOrigin={{ vertical : "top",horizontal :"right"}}
         open={open}
         autoHideDuration={6000}
         onClose={handleClose}
      >
         <div className={divClass}>
            <Alert variant={divClass}>
               {notification.data}
            </Alert>
         </div>
      </Snackbar>}
    </div>
   )
}