import { createSlice } from "@reduxjs/toolkit";

const ActiveSideBarSlice = createSlice({
    name: 'side-bar',
    initialState: {
        activeBar : 'Home'
    },
    reducers: {
        setSideBar (state,action){
            state.activeBar = action.payload
        }
    }
});

export const ActiveSideBarAction = ActiveSideBarSlice.actions;

export default ActiveSideBarSlice;
