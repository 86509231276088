import { createSlice } from "@reduxjs/toolkit";

const sidebarCountSlice = createSlice({
    name : 'sidebar-count',
    initialState : {
        reviewerCount : 0,
        authorCount : 0
    },
    reducers : {
        setReviewerCount(state,action){
            state.reviewerCount = action.payload.count;
        },
        decrementReviewersCount(state,action){
            state.reviewerCount= state.reviewerCount-1;
        },
        setAuthorCount(state,action){
            state.authorCount = action.payload.count;
        },
        decrementAuthorCount(state,action){
            state.authorCount= state.authorCount-1;
        }
    }
});

export const sidebarAction = sidebarCountSlice.actions;

export default sidebarCountSlice;