import { createSlice } from "@reduxjs/toolkit";

const loginStatusSlice = createSlice({
    name: 'loginStatus',
    initialState : {
        isLogin : localStorage.getItem('token')===null?false:true,
    },
    reducers : {
        login(state,action){
            localStorage.setItem('token',action.payload.token);
            localStorage.setItem('userId',action.payload.id)
            state.isLogin=true;
        },
        logout(state,action){
            localStorage.removeItem('token');
            localStorage.removeItem('userId');
            // localStorage.removeItem('intern_id');

            state.isLogin=false;
        }
    }
});

export const loginAction = loginStatusSlice.actions;

export default loginStatusSlice;
