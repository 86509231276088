import Lottie from "react-lottie";
import * as animation404 from './not-found.json'
import redirectFunction from "Components/Utils/redirectFunction";
import { useNavigate } from "react-router-dom";

export default function ErrorComponent() {
    const navigate = useNavigate();
    return (
        <div className="flex justify-center items-center h-[75vh] ">
            <div className="w-[40vw]">
                <Lottie
                    options={{
                        loop: true,
                        autoplay: true,
                        animationData: animation404,
                        rendererSettings: {
                            preserveAspectRatio: "xMidYMid slice"
                        }
                    }}
                    height={300}
                    width={300}

                />
            </div>

            <div className="ml-10 flex flex-col align-center justify-center py-[0.5rem] w-[40%] ">
                <div className="font-poppins font-bold text-5xl mb-[1rem]">Uh Oh</div>
                <div className="font-extralight font-poppins text-md mb-[0.8rem]">The page you are looking for doesn’t exist, was recently moved or has otherwise vanished. Report it to team</div>
                <div>
                    <button onClick={() => redirectFunction(navigate, 'home')} className=" font-poppins border-[0.1rem] rounded-lg p-[0.3rem]">Return to Home</button>
                </div>
            </div>
        </div>
    )
}