import axios from 'axios';
import apis from '../Components/Apis';
// var localStorageToken = localStorage.getItem('token');
// var liveToken = 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MSwiaWF0IjoxNjQ2MDU4ODg3LCJleHAiOjE2NDg2NTA4ODd9.bxw18U5jRuMnte6WYgtLBeM5HOw-VO5pQhUUt79Wd-0'
// const userToken = 'token '+localStorageToken;

// export const  headerToken = () =>{
//     var localStorageToken = localStorage.getItem('token');
//     const userToken = 'token '+localStorageToken;

//     return userToken;
// }
// export const headers = { Authorization: headerToken() }

export async function fetchHomeEvents(offset, limit) {
    const url = `${process.env.REACT_APP_PLATFORM_URL}/api/users/events?limit=${limit}&offset=${offset}`;

    // localStorage.setItem('url', url)
    try {
        const res = await axios.get(url
            , { headers: { Authorization: 'token ' + localStorage.getItem('token') } })
        return res.data;
    }
    catch (error) {
        return [];
    }
}

export async function fetchFilterData(url) {
    // localStorage.setItem('url',url);
    try {
        const res = await axios.get(url
            , { headers: { Authorization: 'token ' + localStorage.getItem('token') } })
        return res.data;
    }
    catch (error) {
        return [];
    }
}

export async function deleteStory(storyId) {
    try {
        const res = await axios.delete(`${process.env.REACT_APP_PLATFORM_URL}/api/users/events/${storyId}`,
            { headers: { Authorization: 'token ' + localStorage.getItem('token') } });
        return res.data;
    }
    catch (error) {
        return [];
    }
}


//
export async function fetchUserProfileData() {
    const userId = localStorage.getItem('userId');
    if (userId) {
        try {
            const res = await axios.get(`${process.env.REACT_APP_PLATFORM_URL}/api/users/account/${userId}`,
                { headers: { Authorization: 'token ' + localStorage.getItem('token') } });

            return res;
        }
        catch (error) {
            return error;
        }
    }
}

export async function fetchPendingReviewStories(filters) {
    const userId = localStorage.getItem('userId');

    if (userId) {
        try {
            let keys = Object.keys(filters), queryParams = "";
            keys.forEach((key) => {
                if (filters[key] || filters[key] === 0) {
                    if (Array.isArray(filters[key])) {
                        let temp = ''
                        filters[key].map((item, index) => {
                            if (index !== filters[key].length - 1) {
                                temp += `${key}=${item}&`

                            }
                            else {
                                temp += `${key}=${item}`
                            }
                        })
                        if (filters[key].length > 0) {
                            queryParams += `&${temp}`
                        }
                    }
                    else {
                        queryParams += `&${key}=${filters[key]}`
                    }
                }
            })

            const res = await axios.get(`${process.env.REACT_APP_PLATFORM_URL}${apis.pending_review}${queryParams ? `?${queryParams}` : ''}&reviewer_id=${userId}`
                , { headers: { Authorization: 'token ' + localStorage.getItem('token') } })

            return res.data;
        }
        catch (error) {
            return error;
        }
    }
}


export async function fetchHomeStoryEvents(status, filters) {
    try {
        let keys = Object.keys(filters), queryParams = "";
        keys.forEach((key) => {
            if (filters[key] || filters[key] === 0) {
                if (Array.isArray(filters[key])) {
                    let temp = ''
                    filters[key].map((item, index) => {
                        if (index !== filters[key].length - 1) {
                            temp += `${key}=${item}&`

                        }
                        else {
                            temp += `${key}=${item}`
                        }
                    })
                    if (filters[key].length > 0) {
                        queryParams += `&${temp}`
                    }
                }
                else {
                    queryParams += `&${key}=${filters[key]}`
                }
            }
        })
        let url = ''
        if (status !== 'home') {
            url = `?status=${status}`;
            if (queryParams) {
                url += `${queryParams}`;
            }
        }
        else {
            if (queryParams) {
                queryParams = queryParams.slice(1);
                url += `?${queryParams}`;
            }
        }


        const res = await axios.get(`${process.env.REACT_APP_PLATFORM_URL}${apis.home_all_story}${url}`
            // const res = await axios.get(`${process.env.REACT_APP_PLATFORM_URL}${apis.home_all_story}${queryParams ? `?${queryParams}` : ''}`
            , { headers: { Authorization: 'token ' + localStorage.getItem('token') } })
        return res.data;
    }
    catch (error) {
        return [];
    }
}

export async function fetchReviewEvents(page, filters) {


    try {

        let keys = Object.keys(filters), queryParams = "";
        keys.forEach((key) => {
            if (filters[key] && filters[key] !== -1 && filters[key] !== "-1") {
                if (key === "status_days" && parseInt(filters[key]) > 8) {
                    queryParams += `from_status_days=${8}&`
                } else if (Array.isArray(filters[key])) {
                    filters[key].forEach((val) => {
                        if (val !== -1) {
                            queryParams += `${key}=${val}&`
                        }
                    })
                }
                else {
                    queryParams += `${key}=${filters[key]}&`
                }
            }

        })
        queryParams += `offset=${(page - 1) * filters.limit}`;


        const res = await axios.get(`${process.env.REACT_APP_PLATFORM_URL}/api/rms/events${queryParams ? `?${queryParams}` : ''}`
            , { headers: { Authorization: 'token ' + localStorage.getItem('token') } })
        return res.data;
    }
    catch (error) {
        return [];
    }
}

export async function fetchAllUserData_ForAdminEvents() {


    try {
        const res = await axios.get(`${process.env.REACT_APP_PLATFORM_URL}/api/users/events?limit=5000&offset=0`
            , { headers: { Authorization: 'token ' + localStorage.getItem('token') } })
        // 
        // 
        return res.data;
    }
    catch (error) {
        return [];
    }
}

export async function fetchStoryPay(id, month, year, filters) {

    try {
        if (id) {
            let keys = Object.keys(filters), queryParams = "";
            keys.forEach((key) => {
                if (filters[key] || filters[key] === 0) {
                    if (Array.isArray(filters[key])) {
                        let temp = ''
                        filters[key].map((item, index) => {
                            if (index !== filters[key].length - 1) {
                                temp += `${key}=${item}&`

                            }
                            else {
                                temp += `${key}=${item}`
                            }
                        })
                        if (filters[key].length > 0) {
                            queryParams += `&${temp}`
                        }
                    }
                    else {
                        queryParams += `&${key}=${filters[key]}`
                    }
                }
            })
            const res = await axios.get(`${process.env.REACT_APP_PLATFORM_URL}${apis.story_pay}${queryParams ? `?${queryParams}` : ''}&user_id=${id}&month=${month}&year=${year}`
                , { headers: { Authorization: 'token ' + localStorage.getItem('token') } })

            return res.data;
        }
        else {
            let keys = Object.keys(filters), queryParams = "";
            keys.forEach((key) => {
                if (filters[key] || filters[key] === 0) {
                    if (Array.isArray(filters[key])) {
                        let temp = ''
                        filters[key].map((item, index) => {
                            if (index !== filters[key].length - 1) {
                                temp += `${key}=${item}&`

                            }
                            else {
                                temp += `${key}=${item}`
                            }
                        })
                        if (filters[key].length > 0) {
                            queryParams += `&${temp}`
                        }
                    }
                    else {
                        queryParams += `&${key}=${filters[key]}`
                    }
                }
            })
            const res = await axios.get(`${process.env.REACT_APP_PLATFORM_URL}${apis.story_pay}${queryParams ? `?${queryParams}` : ''}&month=${month}&year=${year}`
                , { headers: { Authorization: 'token ' + localStorage.getItem('token') } })

            return res.data;
        }
    }
    catch (error) {
        return [];
    }
}



export async function fetchMonthlyPay(id) {

    try {
        if (id) {
            const res = await axios.get(`${process.env.REACT_APP_PLATFORM_URL}/api/payment/payments?user_id=${id}`
                , { headers: { Authorization: 'token ' + localStorage.getItem('token') } })

            return res.data;
        }
        else {
            const res = await axios.get(`${process.env.REACT_APP_PLATFORM_URL}/api/payment/payments`
                , { headers: { Authorization: 'token ' + localStorage.getItem('token') } })

            return res.data;
        }
    }
    catch (error) {
        return [];
    }
}


export async function fetchAnalytics(filters, month, year) {

    try {
        let keys = Object.keys(filters), queryParams = "";
        keys.forEach((key) => {
            if (filters[key] || filters[key] === 0) {
                if (Array.isArray(filters[key])) {
                    let temp = ''
                    filters[key].map((item, index) => {
                        if (index !== filters[key].length - 1) {
                            temp += `${key}=${item}&`

                        }
                        else {
                            temp += `${key}=${item}`
                        }
                    })
                    if (filters[key].length > 0) {
                        queryParams += `&${temp}`
                    }
                }
                else {
                    queryParams += `&${key}=${filters[key]}`
                }
            }
        })
        if (((month === undefined || year === undefined) || (month == undefined || year == undefined))) {
            queryParams += ``;
        }
        else if (((month === null || year === null) || (month == null || year == null))) {
            queryParams += ``;
        }
        else {
            queryParams += `&story_type_month=${month}&story_type_year=${year}`;
        }

        const res = await axios.get(`${process.env.REACT_APP_PLATFORM_URL}${apis.story_performance}${queryParams ? `?${queryParams}` : ''}`
            , { headers: { Authorization: 'token ' + localStorage.getItem('token') } })

        return res.data;
    }
    catch (error) {
        return [];
    }
}

export async function fetchStoryAnalytics(filters) {

    try {
        let keys = Object.keys(filters), queryParams = "";
        keys.forEach((key) => {
            if (filters[key] || filters[key] === 0) {
                if (Array.isArray(filters[key])) {
                    let temp = ''
                    filters[key].map((item, index) => {
                        if (index !== filters[key].length - 1) {
                            temp += `${key}=${item}&`

                        }
                        else {
                            temp += `${key}=${item}`
                        }
                    })
                    if (filters[key].length > 0) {
                        queryParams += `&${temp}`
                    }
                }
                else {
                    queryParams += `&${key}=${filters[key]}`
                }
            }
        })

        const res = await axios.get(`${process.env.REACT_APP_PLATFORM_URL}${apis.analytics_story}${queryParams ? `?${queryParams}` : ''}`
            , { headers: { Authorization: 'token ' + localStorage.getItem('token') } })

        return res.data;
    }
    catch (error) {
        return [];
    }
}

export async function fetchReviewStatus(filters) {

    try {
        let keys = Object.keys(filters), queryParams = "";
        keys.forEach((key) => {
            if (filters[key] || filters[key] === 0) {
                if (Array.isArray(filters[key])) {
                    let temp = ''
                    filters[key].map((item, index) => {
                        if (index !== filters[key].length - 1) {
                            temp += `${key}=${item}&`

                        }
                        else {
                            temp += `${key}=${item}`
                        }
                    })
                    if (filters[key].length > 0) {
                        queryParams += `&${temp}`
                    }
                }
                else {
                    queryParams += `&${key}=${filters[key]}`
                }
            }
        })

        const res = await axios.get(`${process.env.REACT_APP_PLATFORM_URL}${apis.review_status}${queryParams ? `?${queryParams}` : ''}`
            , { headers: { Authorization: 'token ' + localStorage.getItem('token') } })

        return res.data;
    }
    catch (error) {
        return [];
    }
}

export async function deleteStoryEvent(story_id) {
    try {
        const res = await axios.delete(`${process.env.REACT_APP_PLATFORM_URL}${apis.delete_draft_story}${story_id}`,
            { headers: { Authorization: 'token ' + localStorage.getItem('token') } });

        return true;
    }
    catch (error) {
        return false;
    }
}

export async function EditorImageCropperAPI(imageData, imageId) {
    try {
        await axios.put(`${process.env.REACT_APP_PLATFORM_URL}/api/files/upload/${imageId}`,
            imageData
            , { headers: { Authorization: 'token ' + localStorage.getItem('token') } }).then(res => {
                // 
            }).catch(err => err)
    }
    catch (error) {

    }
}

export async function fetchChronicles(filters) {

    try {
        let keys = Object.keys(filters), queryParams = "";
        keys.forEach((key) => {
            if (filters[key] || filters[key] === 0) {
                if (Array.isArray(filters[key])) {
                    let temp = ''
                    filters[key].map((item, index) => {
                        if (index !== filters[key].length - 1) {
                            temp += `${key}=${item}&`

                        }
                        else {
                            temp += `${key}=${item}`
                        }
                    })
                    if (filters[key].length > 0) {
                        queryParams += `&${temp}`
                    }
                }
                else {
                    queryParams += `&${key}=${filters[key]}`
                }
            }
        })
        const res = await axios.get(`${process.env.REACT_APP_PLATFORM_URL}${apis.chronicle_api}${queryParams ? `?${queryParams}` : ''}`
            , { headers: { Authorization: 'token ' + localStorage.getItem('token') } })

        return res.data;
    }
    catch (error) {
        return [];
    }
}

export async function fetchEpisode(id, filters) {
    try {
        let keys = Object.keys(filters), queryParams = "";
        keys.forEach((key) => {
            if (filters[key] || filters[key] === 0) {
                if (Array.isArray(filters[key])) {
                    let temp = ''
                    filters[key].map((item, index) => {
                        if (index !== filters[key].length - 1) {
                            temp += `${key}=${item}&`

                        }
                        else {
                            temp += `${key}=${item}`
                        }
                    })
                    if (filters[key].length > 0) {
                        queryParams += `&${temp}`
                    }
                }
                else {
                    queryParams += `&${key}=${filters[key]}`
                }
            }
        })
        const res = await axios.get(`${process.env.REACT_APP_PLATFORM_URL}${apis.chronicle_api}/${id}/episodes${queryParams ? `?${queryParams}` : ''}`
            , { headers: { Authorization: 'token ' + localStorage.getItem('token') } })
        return res.data;
    }
    catch (error) {
        return [];
    }
}

export async function fetchAdminPay(filters) {

    try {
        let keys = Object.keys(filters), queryParams = "";
        keys.forEach((key) => {
            if (filters[key] || filters[key] === 0) {
                if (Array.isArray(filters[key])) {
                    let temp = ''
                    filters[key].map((item, index) => {
                        if (index !== filters[key].length - 1) {
                            temp += `${key}=${item}&`

                        }
                        else {
                            temp += `${key}=${item}`
                        }
                    })
                    if (filters[key].length > 0) {
                        queryParams += `&${temp}`
                    }
                }
                else {
                    queryParams += `&${key}=${filters[key]}`
                }
            }
        })
        // if(!((val===null || yearVal===null) || (val==0 || yearVal==0))){
        //     queryParams+=`&month=${val}&year=${yearVal}`


        // }

        // else if(val===null && yearVal===null){
        //     queryParams+=`&month=${new Date().getMonth()+1}&year=${new Date().getFullYear()}`

        // }

        // else if(val===null && yearVal!==null){
        //     queryParams+=`&month=${new Date().getMonth()+1}&year=${yearVal}`

        // }
        // else if(val!==null && yearVal===null){
        //     queryParams+=`&month=${val}&year=${new Date().getFullYear()}`

        // }
        // else{
        //     queryParams+=``

        // }

        const res = await axios.get(`${process.env.REACT_APP_PLATFORM_URL}${apis.admin_pay}${queryParams ? `?${queryParams}` : ''}`
            , { headers: { Authorization: 'token ' + localStorage.getItem('token') } })

        return res.data;

    }
    catch (error) {
        return error;
    }
}

export async function PublishChronicleApi(id) {
    try {
        const res = await axios.put(`${process.env.REACT_APP_PLATFORM_URL}${apis.chronicle_api}/${id}/publish`,
            ''
            , { headers: { Authorization: 'token ' + localStorage.getItem('token') } })
        return res;
    }
    catch (error) {
        return [];
    }
}


export async function DeleteDraftStory(id) {
    try {
        const res = await fetch(
            `${process.env.REACT_APP_PLATFORM_URL}/api/users/events/${id}`,
            {
                method: "DELETE",
                headers: { Authorization: "token " + localStorage.getItem("token") },
            }
        );
        return res.status;
    } catch (error) {
        //
        return [];
    }
}

//Task Management

export async function fetchAssignStroy(filters, status) {

    try {
        let keys = Object.keys(filters), queryParams = "";
        keys.forEach((key) => {
            if (filters[key] || filters[key] === 0) {
                if (Array.isArray(filters[key])) {
                    let temp = ''
                    filters[key].map((item, index) => {
                        if (index !== filters[key].length - 1) {
                            temp += `${key}=${item}&`

                        }
                        else {
                            temp += `${key}=${item}`
                        }
                    })
                    if (filters[key].length > 0) {
                        queryParams += `&${temp}`
                    }
                }
                else {
                    queryParams += `&${key}=${filters[key]}`
                }
            }
        })
        if (status) {
            queryParams += `&title_status=${status}`
        }
        // let url = ''
        // if(activeTab!=='home'){
        //     url=`?status=${activeTab}`;
        //     if(queryParams){
        //         url+=`${queryParams}`;
        //     }
        // }
        // else{
        //     if(queryParams){
        //         queryParams = queryParams.slice(1);
        //         url+=`?${queryParams}`;
        //     }
        // }
        const res = await axios.get(`${process.env.REACT_APP_PLATFORM_URL}${apis.assign_story_list}${queryParams ? `?${queryParams}` : ''}`
            , { headers: { Authorization: 'token ' + localStorage.getItem('token') } })
        return res.data;
    }
    catch (error) {
        return [];
    }
}

export async function fetchAdminPool(filters, status) {

    try {
        let keys = Object.keys(filters), queryParams = "";
        keys.forEach((key) => {
            if (filters[key] || filters[key] === 0) {
                if (Array.isArray(filters[key])) {
                    let temp = ''
                    filters[key].map((item, index) => {
                        if (index !== filters[key].length - 1) {
                            temp += `${key}=${item}&`

                        }
                        else {
                            temp += `${key}=${item}`
                        }
                    })
                    if (filters[key].length > 0) {
                        queryParams += `&${temp}`
                    }
                }
                else {
                    queryParams += `&${key}=${filters[key]}`
                }
            }
        })
        if (status) {
            queryParams += `&picked=${status}`
        }

        const res = await axios.get(`${process.env.REACT_APP_PLATFORM_URL}${apis.get_pickup_story_api}${queryParams ? `?${queryParams}` : ''}`
            , { headers: { Authorization: 'token ' + localStorage.getItem('token') } })
        return res.data;
    }
    catch (error) {
        return [];
    }
}


//user


export async function fetchPickUpStory(status, filters) {

    try {
        let keys = Object.keys(filters), queryParams = "";
        keys.forEach((key) => {
            if (filters[key] || filters[key] === 0) {
                if (Array.isArray(filters[key])) {
                    let temp = ''
                    filters[key].map((item, index) => {
                        if (index !== filters[key].length - 1) {
                            temp += `${key}=${item}&`

                        }
                        else {
                            temp += `${key}=${item}`
                        }
                    })
                    if (filters[key].length > 0) {
                        queryParams += `&${temp}`
                    }
                }
                else {
                    queryParams += `&${key}=${filters[key]}`
                }
            }
        })
        let url = ''
        if (status !== 'home') {
            url = `?status=${status}`;
            if (queryParams) {
                url += `${queryParams}`;
            }
        }
        else {
            if (queryParams) {
                queryParams = queryParams.slice(1);
                url += `?${queryParams}`;
            }
        }
        const res = await axios.get(`${process.env.REACT_APP_PLATFORM_URL}${apis.get_pickup_story_api}${queryParams ? `?${queryParams}` : ''}`
            // const res = await axios.get(`${process.env.REACT_APP_PLATFORM_URL}${apis.get_pickup_story_api}`
            , { headers: { Authorization: 'token ' + localStorage.getItem('token') } })
        return res.data;
    }
    catch (error) {
        return [];
    }
}





export async function blockStoryPost(storyList) {
    const object = { "title_ids": storyList }
    try {
        const res = await axios.post(`${process.env.REACT_APP_PLATFORM_URL}${apis.block_story_api}`, object
            // const res = await axios.get(`${process.env.REACT_APP_PLATFORM_URL}${apis.get_pickup_story_api}`
            , { headers: { Authorization: 'token ' + localStorage.getItem('token') } })
        return res.data;
    }
    catch (error) {
        return [];
    }
}

export async function pickedStory(storyList) {
    const object = { "title_ids": storyList }
    try {
        const res = await axios.post(`${process.env.REACT_APP_PLATFORM_URL}${apis.picked_story_api}`, object
            // const res = await axios.get(`${process.env.REACT_APP_PLATFORM_URL}${apis.get_pickup_story_api}`
            , { headers: { Authorization: 'token ' + localStorage.getItem('token') } })
        return res.data;
    }
    catch (error) {
        return [];
    }
}

export async function unblockStory(storyList) {
    const object = { "title_ids": storyList }
    try {
        const res = await axios.post(`${process.env.REACT_APP_PLATFORM_URL}${apis.unblock_story_api}`, object
            // const res = await axios.get(`${process.env.REACT_APP_PLATFORM_URL}${apis.get_pickup_story_api}`
            , { headers: { Authorization: 'token ' + localStorage.getItem('token') } })
        return res.data;
    }
    catch (error) {
        return [];
    }
}

export async function requestStory(story_id) {
    const object = { "event_id": story_id }
    try {
        const res = await axios.post(`${process.env.REACT_APP_PLATFORM_URL}${apis.request_story_api}`, object
            // const res = await axios.get(`${process.env.REACT_APP_PLATFORM_URL}${apis.get_pickup_story_api}`
            , { headers: { Authorization: 'token ' + localStorage.getItem('token') } })
        return res.data;
    }
    catch (error) {
        return [];
    }
}
//fetchStoryRequests

export async function fetchStoryRequests(filters) {

    try {
        let keys = Object.keys(filters), queryParams = "";
        keys.forEach((key) => {
            if (filters[key] || filters[key] === 0) {
                if (Array.isArray(filters[key])) {
                    let temp = ''
                    filters[key].map((item, index) => {
                        if (index !== filters[key].length - 1) {
                            temp += `${key}=${item}&`

                        }
                        else {
                            temp += `${key}=${item}`
                        }
                    })
                    if (filters[key].length > 0) {
                        queryParams += `&${temp}`
                    }
                }
                else {
                    queryParams += `&${key}=${filters[key]}`
                }
            }
        })

        const res = await axios.get(`${process.env.REACT_APP_PLATFORM_URL}${apis.request_story_api}${queryParams ? `?${queryParams}` : ''}`
            // const res = await axios.get(`${process.env.REACT_APP_PLATFORM_URL}${apis.get_pickup_story_api}`
            , { headers: { Authorization: 'token ' + localStorage.getItem('token') } })
        return res.data;
    }
    catch (error) {
        return [];
    }
}


export async function fetchDiscardedStoryRequests(filters) {

    try {
        let keys = Object.keys(filters), queryParams = "";
        keys.forEach((key) => {
            if (filters[key] || filters[key] === 0) {
                if (Array.isArray(filters[key])) {
                    let temp = ''
                    filters[key].map((item, index) => {
                        if (index !== filters[key].length - 1) {
                            temp += `${key}=${item}&`

                        }
                        else {
                            temp += `${key}=${item}`
                        }
                    })
                    if (filters[key].length > 0) {
                        queryParams += `&${temp}`
                    }
                }
                else {
                    queryParams += `&${key}=${filters[key]}`
                }
            }
        })

        const res = await axios.get(`${process.env.REACT_APP_PLATFORM_URL}${apis.get_discarded_story_api}${queryParams ? `?${queryParams}` : ''}`
            // const res = await axios.get(`${process.env.REACT_APP_PLATFORM_URL}${apis.get_pickup_story_api}`
            , { headers: { Authorization: 'token ' + localStorage.getItem('token') } })
        return res.data;
    }
    catch (error) {
        return [];
    }
}

export async function fetchWithChangesDiscardStories(filters) {
    try {
        let keys = Object.keys(filters), queryParams = "";
        keys.forEach((key) => {
            if (filters[key] || filters[key] === 0) {
                if (Array.isArray(filters[key])) {
                    let temp = ''
                    filters[key].map((item, index) => {
                        if (index !== filters[key].length - 1) {
                            temp += `${key}=${item}&`

                        }
                        else {
                            temp += `${key}=${item}`
                        }
                    })
                    if (filters[key].length > 0) {
                        queryParams += `&${temp}`
                    }
                }
                else {
                    queryParams += `&${key}=${filters[key]}`
                }
            }
        })

        const res = await axios.get(`${process.env.REACT_APP_PLATFORM_URL}${apis.get_withChanges_discard_story_api}${queryParams ? `?${queryParams}` : ''}`
            // const res = await axios.get(`${process.env.REACT_APP_PLATFORM_URL}${apis.get_pickup_story_api}`
            , { headers: { Authorization: 'token ' + localStorage.getItem('token') } })
        return res.data;
    }
    catch (error) {
        return [];
    }
}


export async function postSeenWithChanges(ids) {
    const object = {
        "event_ids": ids
    }
    try {
        const res = await axios.post(`${process.env.REACT_APP_PLATFORM_URL}${apis.post_seen_event_reopen}`, object
            // const res = await axios.get(`${process.env.REACT_APP_PLATFORM_URL}${apis.get_pickup_story_api}`
            , { headers: { Authorization: 'token ' + localStorage.getItem('token') } })
        return res.data;
    }
    catch (error) {
        return [];
    }
}


export async function postSeenReopenReq(ids) {
    const object = {
        "request_ids": ids
    }
    try {
        const res = await axios.post(`${process.env.REACT_APP_PLATFORM_URL}${apis.post_seen_reopen_story}`, object
            // const res = await axios.get(`${process.env.REACT_APP_PLATFORM_URL}${apis.get_pickup_story_api}`
            , { headers: { Authorization: 'token ' + localStorage.getItem('token') } })
        return res.data;
    }
    catch (error) {
        return [];
    }
}


export async function post_request_discarded_story(message) {
    const object = {
        "event_id": message.id,
        "request_reason": message.data
    }
    try {
        const res = await axios.post(`${process.env.REACT_APP_PLATFORM_URL}${apis.get_discarded_story_api}`, object
            // const res = await axios.get(`${process.env.REACT_APP_PLATFORM_URL}${apis.get_pickup_story_api}`
            , { headers: { Authorization: 'token ' + localStorage.getItem('token') } })
        return res.data;
    }
    catch (error) {
        return [];
    }
}


export async function fetchNotifications(notificationFilter) {
    const { offset, unread } = notificationFilter
    let api = apis.get_notifications;
    api += `?offset=${offset}&resolved=false`
    if (unread) {
        api += `&unread=true&resolved=false`
    }
    try {
        const res = await axios.get(`${process.env.REACT_APP_PLATFORM_URL}${api}`, { headers: { Authorization: 'token ' + localStorage.getItem('token') } });
        return res.data;
    }
    catch (error) {
        return error
    }
}

export async function fetchCentreNotifications(category, notificationFilter) {
    // console.log(notificationFilter);
    const { start_date, end_date, offset, unread, search } = notificationFilter
    try {
        let queryParams = `offset=${offset}${search.length > 0 ? `&search=${search}` : ``}`;
        if (unread) {
            queryParams += `&unread=true`
        }
        if (start_date && start_date) {
            if (category) {
                queryParams += `&category=${category}&start_date=${start_date}&end_date=${end_date}`
            }
            else {
                queryParams += `&start_date=${start_date}&end_date=${end_date}`;
            }
        }
        else {
            queryParams += ``;
        }


        const res = await axios.get(`${process.env.REACT_APP_PLATFORM_URL}${apis.get_notifications}${queryParams ? `?${queryParams}` : ''}`,
            { headers: { Authorization: 'token ' + localStorage.getItem('token') } });
        return res.data;
    }
    catch (error) {
        return error
    }
}



export async function makeNotificationSeen(data) {
    try {
        const res = await axios.put(`${process.env.REACT_APP_PLATFORM_URL}${apis.seen_notifications}`,
            { "ids": data }
            , { headers: { Authorization: 'token ' + localStorage.getItem('token') } })
        return res;
    }
    catch (error) {
        return [];
    }
}

export async function makeNotificationAsRead(id) {
    try {
        let api = apis.mark_as_read.replace('$[notification_id]', `${id}`)
        const res = await axios.put(`${process.env.REACT_APP_PLATFORM_URL}${api}`,
            ''
            , { headers: { Authorization: 'token ' + localStorage.getItem('token') } })
        return res;
    }
    catch (error) {
        return [];
    }
}

export async function makeNotificationAsUnread(id) {
    try {
        let api = apis.mark_as_unread.replace('$[notification_id]', `${id}`)
        const res = await axios.put(`${process.env.REACT_APP_PLATFORM_URL}${api}`,
            ''
            , { headers: { Authorization: 'token ' + localStorage.getItem('token') } })
        return res;
    }
    catch (error) {
        return [];
    }
}


export async function putClickNotification(id) {
    try {
        let api = apis.click_notifications.replace('$[notification_id]', `${id}`)
        const res = await axios.put(`${process.env.REACT_APP_PLATFORM_URL}${api}`,
            ''
            , { headers: { Authorization: 'token ' + localStorage.getItem('token') } })
        return res;
    }
    catch (error) {
        return [];
    }
}


export async function getNotificationToken() {
    try {
        const res = await axios.post(`${process.env.REACT_APP_PLATFORM_URL}${apis.get_notificaitons_token}`, '',
            { headers: { Authorization: 'token ' + localStorage.getItem('token') } }
        );
        return res;
    }
    catch (error) {
        return []
    }
}

export async function fetchCountryOptions() {
    try {
        const res = await axios.get(`${process.env.REACT_APP_PLATFORM_URL}${apis.get_country_options}`,
            { headers: { Authorization: 'token ' + localStorage.getItem('token') } }
        );
        return res;
    }
    catch (error) {
        return []
    }
}

export async function uploadImageLEditor(imageData, mimeType, name) {
        try {
        var blob = base64ToBlob(imageData, mimeType, name);
        const fd = new FormData();
        fd.append('upload', blob);
        const res = await axios.post(`${process.env.REACT_APP_PLATFORM_URL}/api/files/upload`,
            fd
            , { headers: { Authorization: 'token ' + localStorage.getItem('token') } })
        return res;
    }
    catch (error) {
        console.log("Lexy-boy ", error);
    }
}

function base64ToBlob(base64, mimeType, name) {
    mimeType = mimeType || '';
    var sliceSize = 1024;
    var byteChars = atob(base64.replace(/^data:image\/(png|jpg|jpeg|webp|heic|heif);base64,/, ""));
    var byteArrays = [];

    for (var offset = 0, len = byteChars.length; offset < len; offset += sliceSize) {
        var slice = byteChars.slice(offset, offset + sliceSize);

        var byteNumbers = new Array(slice.length);
        for (var i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
        }

        var byteArray = new Uint8Array(byteNumbers);

        byteArrays.push(byteArray);
    }

    var file = new File([new Blob(byteArrays, { type: mimeType })], Date.now() + "_" + name, { lastModifie: new Date().getTime(), type: mimeType });

    return file;
}