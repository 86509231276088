import { deleteStory, fetchHomeEvents, fetchUserProfileData, fetchReviewEvents, fetchAllUserData_ForAdminEvents,fetchFilterData, fetchHomeStoryEvents, deleteStoryEvent , EditorImageCropperAPI, fetchChronicles, fetchEpisode, PublishChronicleApi, fetchStoryPay,fetchMonthlyPay, fetchAdminPay,fetchStoryAnalytics, DeleteDraftStory, fetchNotifications, fetchCentreNotifications, makeNotificationSeen, getNotificationToken, fetchCountryOptions, fetchReviewStatus} from "./network";
import { updateContactDetail, updateOtherDetail, updatePersonalDetail, updateProfilePic } from "./updateProfileApi";


export async function getHomeEvents(offset, limit) {
  return await fetchHomeEvents(offset, limit);
}

export async function getFilterData(url) {
  return await fetchFilterData(url);
}

export async function deleteStoryFromTable(storyId) {
  return await deleteStory(storyId);
}

export async function getUserProfileData() {
  return await fetchUserProfileData();
}

export async function getPendingReviewStories(filters) {
  return await fetchPendingReviewStories(filters);
}

export async function updatePersonalDetailApi(data) {
  return await updatePersonalDetail(data);
}

export async function updateContactDetailApi(data) {
  return await updateContactDetail(data);
}

export async function updateOtherDetailApi(data) {
  return await updateOtherDetail(data);
}

export async function updateProfilePicApi(picData) {
  return await updateProfilePic(picData);
}

export async function getHomeStoryEvents(status,filters){
    return await fetchHomeStoryEvents(status,filters);
}

export async function getReviewEvents(page, filters) {
  return await fetchReviewEvents(page, filters);
}

export async function getAllUserData() {
  return await fetchAllUserData_ForAdminEvents();
}

export async function deleteDraftStory(story_id){
  return await deleteStoryEvent(story_id);
}

export async function EditorImageCropper(imageData, imageId){
    return await EditorImageCropperAPI(imageData,imageData)
}

export async function getChronicles(filters){
  return await fetchChronicles(filters);
}

export async function getAdminPay(page,filters,val,yearVal){
    return await fetchAdminPay(page,filters,val,yearVal);
}


export async function getStoryAnalytics(filters){
  return await fetchStoryAnalytics(filters);
}

export async function getReviewStatusData(filters) {
  return await fetchReviewStatus(filters);
}

export async function getEpisode(id, filters) {
  return await fetchEpisode(id, filters);
}

export async function getStoryPay(id, month, year, filter) {
  return await fetchStoryPay(id, month, year, filter);
}

export async function getMonthlyPay(id) {
  return await fetchMonthlyPay(id);
}


export async function putPublishChronicle(id){
  return await PublishChronicleApi(id);
}

export async function deleteDraft(id){
  return await DeleteDraftStory(id);
}


//notification

export async function getNotifications(notificationFilter){
  return await fetchNotifications(notificationFilter);
}

export async function getCentreNotifications(category,notificationFilter){
    return await fetchCentreNotifications(category,notificationFilter);
}

export async function putSeenNotification(id_list){
  return await makeNotificationSeen(id_list);
}

export async function getTokenForNotification(){
    return await getNotificationToken();
}

// option for location 

export async function getCountryOptions(){
    return await fetchCountryOptions()
}
