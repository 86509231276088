import { createSlice } from "@reduxjs/toolkit";

const refreshSlice = createSlice({
    name : 'refresh',
    initialState : {
        // data : getUserProfileData()
        refreshHome : false,
        refreshRequest : false
    },
    reducers : {
        refreshHomeTrue(state,action) {
            state.refreshHome  = true;
        },
        refreshHomeReset(state,action) {
            state.refreshHome  = false;
        },
        refreshRequestTrue(state,action) {
            state.refreshRequest  = true;
        },
        refreshRequestReset(state,action) {
            state.refreshRequest  = false;
        }
    }
});

export const refreshAction = refreshSlice.actions;

export default refreshSlice;