import { axiosGet } from "../Components/CoreApiCall";
import apis from "../Components/Apis";


export const COLLAPSE_MENU = 'COLLAPSE_MENU';
export const COLLAPSE_TOGGLE = 'COLLAPSE_TOGGLE';
export const FULL_SCREEN = 'FULL_SCREEN';
export const FULL_SCREEN_EXIT = 'FULL_SCREEN_EXIT';
export const CHANGE_LAYOUT = 'CHANGE_LAYOUT';
export const CHANGE_SUB_LAYOUT = 'CHANGE_SUB_LAYOUT';
export const LAYOUT_TYPE = 'LAYOUT_TYPE';
export const RESET = 'RESET';
export const NAV_BACK_COLOR = 'NAV_BACK_COLOR';
export const NAV_BRAND_COLOR = 'NAV_BRAND_COLOR';
export const HEADER_BACK_COLOR = 'HEADER_BACK_COLOR';
export const RTL_LAYOUT = 'RTL_LAYOUT';
export const NAV_FIXED_LAYOUT = 'NAV_FIXED_LAYOUT';
export const HEADER_FIXED_LAYOUT = 'HEADER_FIXED_LAYOUT';
export const BOX_LAYOUT = 'BOX_LAYOUT';
export const NAV_CONTENT_LEAVE = 'NAV_CONTENT_LEAVE';
export const NAV_COLLAPSE_LEAVE = 'NAV_COLLAPSE_LEAVE';


//ThisDay Add
export const FETCH_START = 'fetch_start';
export const FETCH_SUCCESS = 'fetch_success';
export const FETCH_ERROR = 'fetch_error';
export const UPDATE_AUTH_USER = 'update_auth_user';
export const UPDATE_LOAD_USER = 'update_load_user';
export const SEND_FORGET_PASSWORD_EMAIL = 'send_forget_password_email';

export const FETCH_IMAGES = "FETCH_IMAGES";
export const SELECTED_IMAGES = "SELECTED_IMAGES";
export const SET_GALLERY_TYPE = "SET_GALLERY_TYPE";
export const SET_GALLERY_OPEN = "SET_GALLERY_OPEN";
export const SET_UPLOADING = "SET_UPLOADING";
export const SET_CAROUSEL_REF= "SET_CAROUSEL_REF";
export const SET_FINAL_CHECK= "SET_FINAL_CHECK";
export const fetchSuccess = message => {
    return dispatch => {
        dispatch({
            type: FETCH_SUCCESS,
            payload: message || '',
        });
    };
};
export const fetchError = error => {
    return dispatch => {
        dispatch({
            type: FETCH_ERROR,
            payload: error,
        });
    };
};

export const fetchStart = () => {
    return dispatch => {
        dispatch({
            type: FETCH_START,
        });
    };
};

export const setAuthUser = user => {
    return dispatch => {
        dispatch({
            type: UPDATE_AUTH_USER,
            payload: user,
        });
    };
};

export const updateLoadUser = loading => {
    return dispatch => {
        dispatch({
            type: UPDATE_LOAD_USER,
            payload: loading,
        });
    };
};

export const setForgetPassMailSent = status => {
    return dispatch => {
        dispatch({
            type: SEND_FORGET_PASSWORD_EMAIL,
            payload: status,
        });
    };
};

//editor actions
export function getImageList(page=1){
    let request = axiosGet(`${apis.image_upload_api}?limit=12&offset=${(page-1)*12}`,true);
   return (dispatch)=>{
       
       request.then((result)=>{
          if(result && result.data){
              dispatch({
                  type: FETCH_IMAGES,
                  payload:{
                      data:result.data
                  }
              })
          }
       })
   }

}



export function saveSelectedItems(items){

    return {
        type: SELECTED_IMAGES,
        payload:{
            data:items
        }
    }

}

export function clearDispatchAction(type){

    return {
        type:type,
        payload:{
            data:[]
        }
    }

}

export function setActionData(type,data){
    return {
        type:type,
        payload:data
    }

}
//