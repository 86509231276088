import { createSlice } from "@reduxjs/toolkit";
// import isEqual from "lodash/isEqual";

const initialTags = {
  tag : null,
  id : null
}
const initialTagState = {
  tags : [initialTags],
  tempTags : [initialTags],
  focus : false
};

const tagsOptions = createSlice({
  name: "tags",
  initialState: initialTagState,
  reducers: {
    setFocus(state,action){
      state.focus=action.payload.focus;
    },
    setTags(state,action){
      const {tags} = action.payload
      let tagsData =[];
      tags?.map((item)=>{
        const obj = {};
        obj["tag"] = item.tag;
        obj["id"] = item.id;
        tagsData.push(obj);
      });
      state.tags = tagsData;
      state.tempTags = tagsData;
    },
    addTag (state,action) {
        const newTag = {
            tag : action.payload.tag,
            id : null
        }
        state.tags.push(newTag);
    },
    setIdToTag(state,action){
        state.tags[state.tags.length-1].id = action.payload.id
    },
    removeTag(state,action){
        const { id :tagId,ind} = action.payload;
        state.tags.splice(ind,1);
    },
    resetTags(state,action){
      state.tags = [initialTagState]
      state.tempTags = [initialTagState]
    },
    discard(state){
      state.tags = state.tempTags;
    }
  },
});

export const tagsOptionsAction = tagsOptions.actions;

export default tagsOptions;
