import { createSlice } from "@reduxjs/toolkit";

//Please add your source as unique whenever you want to use this reducer
// and always call remove() whenever your state destroys 
// when ever clear filter or searching always pass limit=10 and offset=0

const initialYearFilterState = { 
  limit: 10,
  offset: 0,
  sort: "",
  status: [],
  search: "",
  leader: [],
  author: [],
  sort_by_name: "",
  sort_by_doj: "",
  is_active: "",
  payment_status: "",
  period_id: [],
  status_list: [],
  version_id: null,
  from_submission_date: "",
  to_submission_date: "",
  year:"",
  month:"",
  type:'',       //for user and admin => pickup story type
  status:''     //for user => pickup story type
};

const yearFilterSlice = createSlice({
  name: "yearfilter",
  initialState: {
    source: "",
    yearFilters: initialYearFilterState,
    clearFilter: false,
  },
  reducers: {
    setFilter(state, action) {
      
      const monthVal=action.payload.month
      const yearVal=action.payload.year
      const yearFilterObj={
        ...state.yearFilters,
        ...action.payload
      }
      if(monthVal && monthVal!==state.yearFilters.month){
          yearFilterObj.offset=0;
      }
      if(yearVal && yearVal!==state.yearFilters.year){
        yearFilterObj.offset=0;
    }
      state.yearFilters = yearFilterObj;
      delete state.yearFilters.source;
      delete state.yearFilters.clearFilter;
      state.source = action.payload.source;
      if (action.payload.clearFilter) {
        state.clearFilter = action.payload.clearFilter;
      }
    },
    removeFilter(state, action) {
      state.yearFilters = initialYearFilterState;
      state.source = action.payload.source;
      state.clearFilter = false;
    },
    removePagination(state, action) {
      const yearFilters = {
        ...state.yearFilters,
        limit: 10,
        offset: 0,
      };
      state.yearFilters = yearFilters;
      state.source = action.payload.source;
      state.clearFilter = false;
    },
  },
});

export const yearFilterAction = yearFilterSlice.actions;

export default yearFilterSlice;
