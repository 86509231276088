import { createSlice } from "@reduxjs/toolkit";

//Please don't change anything

const initialPickUpStoryState = {
    pickedUpStory : [],
    allIdAvailable : [],
    allChecked: false,
    showSuccessModal:false,
    selectedData : [],    
};

const pickUpStorySlice = createSlice({
  name: "pick-up-story",
  initialState: initialPickUpStoryState,
  reducers: {
    addStory(state,action){
      // state.selectedCheckbox.push(action.payload.data);
      state.pickedUpStory.push(action.payload.id);
      state.selectedData = [...state.selectedData,action.payload.data];
    },
    removeStory(state,action){
      state.pickedUpStory =  state.pickedUpStory.filter(item=>item!==action.payload.index);
      state.allChecked = false;
      state.selectedData = state.selectedData.filter(item=>item?.id!==action.payload.index);
    },
    allCheckeddTrue(state,action){
      state.allChecked=true;
      state.pickedUpStory = [...new Set([...state.pickedUpStory,...state.allIdAvailable])];
    },
    resetReducer(state,action){
      state.pickedUpStory= [];
      state.allChecked = false;
    },
    removeChecked(state,action){
      // console.log(action.payload);
      state.allChecked = false;
      state.pickedUpStory = action.payload;
    },
    resetAvailableId(state){
      state.allIdAvailable = initialPickUpStoryState.allIdAvailable;
      state.allChecked=false;
    },
    resetReducer(state){
      state.pickedUpStory= [];
      //state.allChecked = false;
    },
    addAvaibleOption(state,action){
      if(!state.allIdAvailable.includes(action.payload.id)){
        state.allIdAvailable.push(action.payload.id);
      }
    },
    pickedStoryAction(state,action){
      state.showSuccessModal = true;
    },
    filterBlur(state,action){
      state.pickedUpStory = state.pickedUpStory.filter(item=>!action.payload.data.includes(item));
      state.selectedData = state.selectedData.filter(item=>!action.payload.data.includes(item.id));
    },
    pickedStoryCompleted(state,action){
      state.pickedUpStory = initialPickUpStoryState.pickedUpStory;
      state.allIdAvailable = initialPickUpStoryState.allIdAvailable;
      state.allChecked = initialPickUpStoryState.allChecked;
      state.showSuccessModal = initialPickUpStoryState.showSuccessModal;
      state.selectedData = initialPickUpStoryState.selectedData;
    }
  },
});

export const pickUpStoryAction = pickUpStorySlice.actions;

export default pickUpStorySlice;
