import { FETCH_IMAGES, SELECTED_IMAGES, SET_CAROUSEL_REF, SET_GALLERY_OPEN, SET_GALLERY_TYPE, SET_UPLOADING,SET_FINAL_CHECK } from "../actions";

const InitialState ={
	images_list : [],
	selected_images : [],
    gallery_type:"cover_image",
    open_gallery:false,
    start_uploading:false,
    carousel_ref:null,
    final_check:0

};

export const reducer = (state=InitialState,action)=>{

	switch(action.type){
	case FETCH_IMAGES:
		return {
			...state,
			images_list : action.payload && action.payload.data
		};

        case SELECTED_IMAGES:
            return {
                ...state,
                selected_images : action.payload && action.payload.data
            };
        
            case SET_GALLERY_TYPE:
                return {
                    ...state,
                    gallery_type: action.payload && action.payload.type
                };
                case SET_GALLERY_OPEN:
                    return {
                        ...state,
                        open_gallery: action.payload && action.payload.open
                    };
                    case SET_UPLOADING:
                        return {
                            ...state,
                            start_uploading: action.payload && action.payload.start
                        };
                        case SET_CAROUSEL_REF:
                            return {
                                ...state,
                                carousel_ref: action.payload && action.payload.ref
                            };
                            case SET_FINAL_CHECK:
                                return {
                                    ...state,
                                    final_check: action.payload && action.payload.data
                                };

            
                
	default:
		return {
			...state
		};
	}
};
