import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import './index.css'

import App from './App/index';
import * as serviceWorker from './serviceWorker';
import config from './config';
import store from './store/index'
import './assets/scss/style.scss';
import "./assets/css/Global.css"
import zipy from "zipyai";
import NotificationManager from './Util Components/Notification/Notification';
import { CookiesProvider } from 'react-cookie';



if (process.env.NODE_ENV === "production" || process.env.NODE_ENV === "staging") {
    Sentry.init({
        dsn: process.env.REACT_APP_SENTRY_DNS,
        environment: process.env.NODE_ENV,
        integrations: [new BrowserTracing()],

        // We recommend adjusting this value in production, or using tracesSampler
        // for finer control
        tracesSampleRate: 1.0,
    });

    // Added Zippy 
    zipy.init(process.env.REACT_APP_ZIPY_DNS);
}





const app = (

    <Provider store={store}>
        
        <BrowserRouter basename={config.basename}>
            <CookiesProvider>
                <App />
            </CookiesProvider>
        </BrowserRouter>
        <NotificationManager />
        
    </Provider>
);

ReactDOM.render(app, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.register();
