import { configureStore } from '@reduxjs/toolkit';
import reducer from './reducer/reducer';
import reducerAuth from './reducer/reducer-auth';
import loginStatusSlice from './reducer/reducer-login';
import userDataSlice from './reducer/reducer-userData';
import { reducer as reducerEditor} from './reducer/reducer-editor'
import storyListSlice from './reducer/reducer-homeTable';
import ReviewerLocalCommentsSlice from './reducer/ReviewerLocalComments-reducer';
import AuthorLocalCommentsSlice from './reducer/AuthorLocalComments-reducer';
import filterSlice from './reducer/reducer-filter';
import yearFilterSlice from './reducer/reducer-yearFilter';
import storyWiseFilterSlice from 'containers/user/Performance/reducer/reducer-storyWise';
import reviewStatusFilterSlice from 'Components/AdminPanel/reviewStatus/reducer/reducer-review';
import  notificationReducer from './reducer/notification';
import editorReducer from './reducer/editorReducer';
import pickUpStorySlice from './reducer/reducer-pickUpStory';
import pickUpTitleSlice from './reducer/reducer-pickUpTitle';
import refreshSlice from './reducer/reffresh-reducer';
import NotificationToastSlice from './reducer/notification-reducer';
import ActiveSideBarSlice from './reducer/activeSideBar-reducer';
import chronicleFilterSlice from 'containers/admin/taskManagement/chronicle/reducer/reducer-chronicle';
import chronicleEpisodeFilterSlice from 'containers/admin/taskManagement/chronicle/reducer/reducer-episode';
import sidebarCountSlice from './reducer/sideBarCount';
import loaderSlice from './reducer/editorLoading';
// import locationOptions from './reducer/location-reducer';
import tagsOptions from './reducer/tags-reducer';
// import filterSlice from './reducer/filter-reducer';
const store = configureStore(
    {
        reducer: {
            legacy: reducer,
            auth: reducerAuth,
            login : loginStatusSlice.reducer,
            userInfo : userDataSlice.reducer,
            editor : reducerEditor,
            storyList : storyListSlice.reducer,
            reviewLocalComments : ReviewerLocalCommentsSlice.reducer,
            authorLocalComments : AuthorLocalCommentsSlice.reducer,
            filter : filterSlice.reducer,
            yearFilter : yearFilterSlice.reducer,
            storyWiseFilter:storyWiseFilterSlice.reducer,
            reviewStatusFilter:reviewStatusFilterSlice.reducer,
            notificationReducer : notificationReducer,
            editorController : editorReducer,
            pickUpStory : pickUpStorySlice.reducer,
            pickUpTitle : pickUpTitleSlice.reducer,
            refresh : refreshSlice.reducer,
            notificationToast : NotificationToastSlice.reducer,
            activeSideBar : ActiveSideBarSlice.reducer,
            chronicleFilters : chronicleFilterSlice.reducer,
            chronicleEpisodeFilter:chronicleEpisodeFilterSlice.reducer,
            sidebarCount : sidebarCountSlice.reducer,
            loader : loaderSlice.reducer,
            // locationStore : locationOptions.reducer,
            tagStore:tagsOptions.reducer
            // filters : filterSlice.reducer
        },
        middleware: (getDefaultMiddleware) =>
            getDefaultMiddleware({
                serializableCheck: false,
            }),
    }
);

export default store;