import { createSlice } from "@reduxjs/toolkit";
import { getUserProfileData, updateOtherDetailApi, updatePersonalDetailApi, updateProfilePicApi ,updateContactDetailApi } from "../../api";

const userDataSlice = createSlice({
    name : 'userData',
    initialState : {
        data : getUserProfileData()
    },
    reducers : {
        fetch_user_data(state,action){
            state.data = action.payload;
        },
        update_personal_details(state,action){
            updatePersonalDetailApi(action.payload);
            state.data = getUserProfileData();
        },
        update_other_details(state,action){
            updateOtherDetailApi(action.payload);
            state.data = getUserProfileData();
        },
        update_contact_details(state,action){
            updateContactDetailApi(action.payload);
            state.data = getUserProfileData();
        },
        update_profile(state,action){ 
            updateProfilePicApi(action.payload);
            state.data=getUserProfileData();
        }
    }
});

export const userDataAction = userDataSlice.actions;

export default userDataSlice;