const apis ={
    image_upload_api:"/api/files/upload",
    event_upload_api:"/api/users/events",
    comments_api:"/api/rms/events/$[id]/comments",
    review_event_api:"/api/stories/events",
    comment_api:"/api/rms/comments",
    reply_api:"/api/rms/replies",
    event_type_api:"/api/stories/types",
    approve_event:"/api/rms/events/$[id]/approve",
    resolved_comment:"/api/rms/comments/$[id]/resolve",
    discard_event:"/api/rms/events/$[id]/discard",
    event_api:"/api/rms/event-revisions",
    history_api:"/api/rms/event-revisions/$[id]/version-history",
    reviewers_data:"/api/rms/user-analysis/reviewer",
    pending_review:"/api/rms/events/in-progress-events-before-inactive-request",
    author_data:"/api/rms/user-analysis/author",
    home_all_story:'/api/users/events',
    analytic_counts:'/api/users/events/count',
    final_check:"/api/rms/events/final",
    published_stories_admin :'/api/rms/events/published',
    admin_events_counts:'/api/stories/event-requests/counts',
    publish_api:"/api/rms/events/publish?event_id=$[id]",
    disapprove_event:"/api/rms/events/disapprove?event_id=$[id]&failed_reason=$[text]",
    inactive_requests:"/api/rms/inactive-requests",
    inactive_users:"/api/rms/inactive-requests/users",
    allow_user_request:"/api/rms/inactive-requests/$[id]/approve",
    discard_user_request:"/api/rms/inactive-requests/$[id]/reject",
    make_user_active:"/api/users/account/$[id]/activate/reviewer",
    get_reviewers:"/api/rms/event-revisions/$[id]/reviewers",
    delete_draft_story:'/api/users/events/',
    intern_api : '/api/users/account',
    publish_by_admin_filter_api : '/api/rms/user-analysis/admins',
    chronicle_api : '/api/rms/chronicles',
    chronicle_leader_api : '/api/users/account?chronicle_leaders=True',
    story_pay:'/api/payment/user-points',
    monthly_pay:'/api/payment/payments',
    analytics_story:'/api/users/events/analytics_story_list',
    admin_pay:'/api/payment/payments/admin',
    story_performance:'/api/users/events/analytics',
    story_likes_reads_api:'/api/users/events/event_likes',
    story_type_count_api:'/api/users/events/story_count_type_wise',
    story_wise_performance:'/api/users/events/analytics_story_list',
    review_status:'/api/stories/events/pending-reviews',
    story_reviewed_and_published:'/api/users/events/analytics',
    story_written_and_published:'/api/users/events/written-published',
    // episode_api : '/api/rms/chronicles/$[chronicle_id]/episodes',
    
    //Task Management APIs
    assign_story_list:'/api/stories/topics',
    assign_story_api:'/api/stories/topics/assign-titles',
    get_pool_list:'/api/stories/topics/pool_list',
    edit_story_api:'/api/stories/topics/',
    create_bucket_api:'/api/stories/author-buckets',
    edit_title_api:'/api/stories/topics',
    bulk_upload_api:'/api/stories/topics/bulk-upload',
    move_to_pool_api:'/api/stories/topics/move_story_title_to_pool',
    story_types_api:'/api/stories/types',
    story_niches_api:'/api/stories/niches',
    story_language_api:'/api/stories/languages',
    chronicle_edit_detail_api:'/api/stories/topics/topic-episodes',
    story_author_buckets_api:'/api/stories/author-buckets',
    add_episode_api:'/api/stories/episodes/create-episode',
    download_admin_inventory_api:'/api/stories/topics/download-topics',
    update_chronicle_title_api:'/api/stories/chronicles/',

    //User Management
    get_pickup_story_api:'/api/stories/topics/pool_list',
    block_story_api:'/api/stories/topics/block-titles',
    unblock_story_api:'/api/stories/topics/unblock_titles',
    picked_story_api:'/api/stories/topics/author/pickup-titles',
    request_story_api: '/api/stories/topic-requests',
    delete_story_api:'/api/stories/topics/delete-topics',
    get_discarded_story_api:'/api/stories/event-requests',
    post_seen_event_reopen:'/api/stories/events/event_reopen_request_seen',
    post_seen_reopen_story : '/api/stories/event-requests/event_request_seen',
    get_withChanges_discard_story_api:'/api/stories/events/discarded_stories',
    //notification fetch
    get_notificaitons_token : '/users/ott',
    get_notifications: '/api/notifications/web-notifications',
    seen_notifications : '/api/notifications/web-notifications/mark-seen',
    mark_as_read : '/api/notifications/web-notifications/$[notification_id]/read',
    mark_as_unread : '/api/notifications/web-notifications/$[notification_id]/unread',
    click_notifications : '/api/notifications/web-notifications/$[notification_id]/click',
    
    //location option
    get_country_options : '/api/stories/countries?limit=300',

}

export default apis;