import { createSlice } from "@reduxjs/toolkit";

var initialStoryWiseFilterState = {
  limit: 5,
  offset: 0,
  "first_date": "",
  "last_date": "",
};

const storyWiseFilterSlice = createSlice({
  name: "storyWiseFilter",
  initialState: {
    source: "",
    storyWiseFilter: initialStoryWiseFilterState,
    clearFilter: false,
  },
  reducers: {
    setFilter(state, action) {
      state.storyWiseFilter = Object.assign(state.storyWiseFilter, action.payload);
      delete state.storyWiseFilter.source;
      delete state.storyWiseFilter.clearFilter;
      state.source = action.payload.source;
      if (action.payload.clearFilter) {
        state.clearFilter = action.payload.clearFilter;
      }
    },
    removeFilter(state, action) {
      state.storyWiseFilter = initialStoryWiseFilterState;
      state.source = action.payload.source;
      state.clearFilter = false;
    },
    removePagination(state, action) {
      const filter = {
        ...state.storyWiseFilter,
        limit: 5,
        offset: 0,
      };
      state.storyWiseFilter = filter;
      state.source = action.payload.source;
      state.clearFilter = false;
    },
  },
});

export const storyWiseFilterAction = storyWiseFilterSlice.actions;

export default storyWiseFilterSlice;
