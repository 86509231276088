import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'

export enum NState {
    ERROR,SUCCESS,PROCESSING,NULL
}

export interface INotificationState{
    type : NState|undefined,
    data : string | null 
}

const initalState = {
    type : NState.NULL,
    data : null
} as INotificationState

const NotificationSlice = createSlice({
    name : "NotificationReducer",
    initialState : initalState,
    reducers : {
        setNotification : (state,action: PayloadAction<INotificationState>) => {
            state.type = action.payload.type;
            state.data = action.payload.data
        }
    }
})

export const {setNotification} = NotificationSlice.actions;
export default NotificationSlice.reducer 