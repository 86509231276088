import { createSlice } from "@reduxjs/toolkit";

const ReviewerLocalCommentsSlice = createSlice({
    name: 'Reviewer Local Comments',
    initialState : {
        comments : []
    },
    reducers : {
        addComment(state,action){
            state.comments = [...state.comments,action.payload];
        },
        deleteComment(state,action){
            const filteredComment = state.comments.filter((comment)=>comment.comment_id!==action.payload)
            state.comments = filteredComment;
        }
        // login(state,action){
        //     localStorage.setItem('token',action.payload.token);
        //     localStorage.setItem('userId',action.payload.id)
        //     state.isLogin=true;
        // },
        // logout(state,action){
        //     localStorage.removeItem('token');
        //     localStorage.removeItem('userId');
            // localStorage.removeItem('intern_id');
        //     state.isLogin=false;
        // }
    }
});

export const ReviewerLocalCommentsAction  = ReviewerLocalCommentsSlice.actions;

export default ReviewerLocalCommentsSlice;
