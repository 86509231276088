import { createSlice } from "@reduxjs/toolkit";
import { TStoryState } from "./editorReducer";

export interface ILoaderState {
    'loading' : boolean,
    'error' : string | null,
    'storyType' : TStoryState | null
}


const initalState :ILoaderState = {
    loading : false,
    error : null,
    storyType : null
}

const loaderSlice = createSlice(
    {
        name : 'loaderReducer',
        initialState : initalState,
        reducers : {
            setLoaderState : (state, action) => {
                state.loading = action.payload.loading
                state.error = action.payload.error
                state.storyType = action.payload.storyType
            }
        }
    }
)

export const { setLoaderState } = loaderSlice.actions
export default loaderSlice
