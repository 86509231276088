import axios from "axios";


export async function updatePersonalDetail(data) {
    const userId = localStorage.getItem('userId');
 

    try {
        await axios.put(`${process.env.REACT_APP_PLATFORM_URL}/api/users/account/${userId}`,
        {
            "about" :data.updatedAbout,
            "name" : data.updatedName,
            "username" : data.updatedUserName,
            "date_of_birth" : data.updatedBirthDate,
            "location" : data.updatedLocation
        },
        { headers: {Authorization : 'token '+ localStorage.getItem('token')} })
    }
    catch(error){
        return error    
    }
}

export async function updateContactDetail(data) {
    const userId = localStorage.getItem('userId');

    try {
        await axios.put(`${process.env.REACT_APP_PLATFORM_URL}/api/users/account/${userId}`,
        {
            "phone_number" : data,
            // "email" : data.updatedEmail
        },
        { headers: {Authorization : 'token '+ localStorage.getItem('token')} })
    }
    catch(error){
        return error
    }
}

export async function updateOtherDetail(data) {
    const userId = localStorage.getItem('userId');

    try {
        await axios.put(`${process.env.REACT_APP_PLATFORM_URL}/api/users/account/${userId}`,
        {
            "interest" : data.updatedInterest,
            // "prefered_language" : data.updatedPreferredLanguage,
            "fav_books" : data.updatedFavouriteBook,
            "fav_movies" : data.updatedFavouriteMovies,
            "fav_historical_characters" : data.updatedFavouriteHistoryChar
        },
        { headers: {Authorization : 'token '+ localStorage.getItem('token')}})

    }
    catch(error){
        return error;
    }
}

export async function updateProfilePic(picData) {
    const userId = localStorage.getItem('userId');
    
    try {
        await axios.put(`${process.env.REACT_APP_PLATFORM_URL}/api/users/account/${userId}`,
            picData
        ,{ headers: {Authorization : 'token '+ localStorage.getItem('token')} }).then(res=>{
            // 
        }).catch(err =>err);
    }
    catch(error){
        return error;
        
    }
}