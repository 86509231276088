import { createSlice } from "@reduxjs/toolkit";
import isEqual from "lodash/isEqual";

// Please add your source as unique whenever you want to use this reducer
// and always call remove() whenever your state destroys 
// when ever clear filter or searching always pass limit=10 and offset=0
// also if you want to make a new file oout of it please remember to change the name in filterSlice

const initialFilterState = {
  limit: 10,
  offset: 0,
  sort: "",
  status: [],
  search: "",
  order_by:"",
  leader: [],
  author: [],
  sort_by_name: "",
  sort_by_doj: "",
  is_active: "",
  payment_status: "",
  period_id: [],
  status_list: [],
  version_id: null,
  from_submission_date: "",
  type_ids:[],
  to_submission_date: "",
  type:'',       //for user and admin => pickup story type
  status:'',
  language:''     //for user => pickup story type
};

const filterSlice = createSlice({
  name: "filter",
  initialState: {
    source: "",
    filters: initialFilterState,
    clearFilter: false,
  },
  reducers: {
    setFilter(state, action) {
      const limitVal=action.payload.limit;
      const searchVal=action.payload.search;

      const filterObj={
        ...state.filters,
        ...action.payload
      }

      if(limitVal && limitVal!==state.filters.limit){
        filterObj.offset=0
      }

      if(searchVal && searchVal!==state.filters.search){
        filterObj.offset=0
      }

      state.filters = filterObj;
      
      delete state.filters.source;
      delete state.filters.clearFilter;
      state.source = action.payload.source;
      if (action.payload.clearFilter) {
        state.clearFilter = action.payload.clearFilter;
      }
      if (isEqual(state.filters, initialFilterState)) {
        state.filters = initialFilterState;
        state.source = action.payload.source;
        state.clearFilter = false;
      }
    },
    removeFilter(state, action) {
      state.filters = initialFilterState;
      if(action.payload.offset){
        const filter= {
          ...state.filters,
          offset : action.payload.offset
        }
        state.filters = filter;
      }
      state.source = action.payload.source;
      state.clearFilter = false;
    },
    removePagination(state, action) {
      const filter = {
        ...state.filters,
        limit: 10,
        offset: 0,
      };
      state.filters = filter;
      state.source = action.payload.source;
      state.clearFilter = false;
    },
  },
});

export const filterAction = filterSlice.actions;

export default filterSlice;
