import { createSlice } from "@reduxjs/toolkit";

var initialReviewStatusFilterState = {
  limit: 5,
  offset: 0,
  search: "",
  first_date: "",
  last_date: "",
};

const reviewStatusFilterSlice = createSlice({
  name: "reviewStatusFilter",
  initialState: {
    source: "",
    reviewStatusFilter: initialReviewStatusFilterState,
    clearFilter: false,
  },
  reducers: {
    setFilter(state, action) {
      
      const searchVal= action.payload.search
      const limitVal=action.payload.limit
      let filterObj={
        ...state.reviewStatusFilter,
        ...action.payload
      }
      if(limitVal && limitVal!==state.reviewStatusFilter.limit){
      filterObj.offset=0
      }
      if(searchVal && searchVal!==state.reviewStatusFilter.search){
        filterObj.offset=0
      }
      state.reviewStatusFilter = filterObj
      delete state.reviewStatusFilter.source;
      delete state.reviewStatusFilter.clearFilter;
      state.source = action.payload.source;
      if (action.payload.clearFilter) {
        state.clearFilter = action.payload.clearFilter;
      }
    },
    removeFilter(state, action) {
      state.reviewStatusFilter = initialReviewStatusFilterState;
      state.source = action.payload.source;
      state.clearFilter = false;
    },
    removePagination(state, action) {
      const filter = {
        ...state.reviewStatusFilter,
        limit: 5,
        offset: 0,
      };
      state.reviewStatusFilter = filter;
      state.source = action.payload.source;
      state.clearFilter = false;
    },
  },
});

export const reviewStatusFilterAction = reviewStatusFilterSlice.actions;

export default reviewStatusFilterSlice;
