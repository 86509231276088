import { createSlice } from "@reduxjs/toolkit";

const NotificationToastSlice = createSlice({
  name: "NotificationToast",
  initialState: [],
  reducers: {
    addNotificationToast (state,action) {
        state = state.push(action.payload.data)
    }
  },
});

export const NotificationToastAction = NotificationToastSlice.actions;

export default NotificationToastSlice;
