import { createSlice } from "@reduxjs/toolkit";

//Please don't change anything

const initialPickUpTitleState = {
    pickedUpTitle : [],
    allAvailID : [],
    allCheck: false,
    setSuccessModal:false,
    selectedId : [],    
};

const pickUpTitleSlice = createSlice({
  name: "pick-up-title",
  initialState: initialPickUpTitleState,
  reducers: {
    addTitle(state,action){
      state.pickedUpTitle.push(action.payload.id);
      state.selectedId = [...state.selectedId,action.payload.data];
    },
    removeTitle(state,action){
      state.pickedUpTitle =  state.pickedUpTitle.filter(item=>item!==action.payload.id);
      state.allCheck = false;
      state.selectedId = state.selectedId.filter(item=>item?.id!==action.payload.id);
    },
    allCheckdTrue(state,action){
      state.allCheck=true;
      state.pickedUpTitle = [...new Set([...state.pickedUpTitle,...state.allAvailID])];
    },
    resetReducer(state,action){
      state.pickedUpTitle= [];
      state.allCheck = false;
    },
    removeChecked(state,action){
      // console.log(action.payload);
      state.allCheck = false;
      state.pickedUpTitle = action.payload;
    },
    resetAvailableId(state){
      state.allAvailID = initialPickUpTitleState.allAvailID;
      state.allCheck=false;
    },
    addAvaibleOption(state,action){
      if(!state.allAvailID.includes(action.payload.id)){
        state.allAvailID.push(action.payload.id);
      }
    },
    pickedStoryAction(state,action){
      state.setSuccessModal = true;
    },
    filterBlur(state,action){
      state.pickedUpTitle = state.pickedUpTitle.filter(item=>!action.payload.data.includes(item));
      state.selectedId = state.selectedId.filter(item=>!action.payload.data.includes(item.id));
    },
    pickedStoryCompleted(state,action){
      state.pickedUpTitle = initialPickUpTitleState.pickedUpTitle;
      state.allAvailID = initialPickUpTitleState.allAvailID;
      state.allCheck = initialPickUpTitleState.allCheck;
      state.setSuccessModal = initialPickUpTitleState.setSuccessModal;
      state.selectedId = initialPickUpTitleState.selectedId;
    }
  },
});

export const pickUpTitleAction = pickUpTitleSlice.actions;

export default pickUpTitleSlice;
