import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'

export enum TStoryState {
    DRAFT ='draft',
    INREVIEW = 'in-review',
    PUBLISHED = 'published',
    FINALCHECK = 'in-final-check',
    DISCARDED = 'failed_in_final_check'
}

export enum ROLE {
    AUTHOR = 'author',
    REVIEWER = 'reviewer',
    ADMIN = 'admin'
}

export interface IEditorAPIState{
    bundle_version : string,
    created_at : string,
    data : string | null,
    document_id : string,
    event : number,
    status : TStoryState,
    title : null,
    token_url : string,
    websocket_url : string,
    track_changes : boolean,
    reviewer_event_id : number | null,
    time_taken : number,
    author_id : number | null,
    sources : {id : number,source : string}[],
    author_name : string | null,
    my_name: string | null,
    document_role ? : ROLE,
    isEpisode :boolean,
    discarded_reason : string | null,
    date:string | null,
    event_locations: any,
    tags: any,
    event_niches : any,
    period: any,
    event_themes:any,   
    event_types : any,
    language: any,
    is_picked_from_pool:boolean | null,
}

const initalState : IEditorAPIState = {
    bundle_version : "",
    created_at : "",
    data : null,
    document_id : "",
    event : 0,
    status : TStoryState.DRAFT,
    title :  null,
    token_url : "",
    websocket_url : "",
    track_changes : false,
    reviewer_event_id : null,
    time_taken : 0,
    author_id : null,
    sources  : [],
    author_name : null,
    my_name : null,
    isEpisode: false,
    discarded_reason : "",
    date:null,
    event_locations: [],
    tags: [],
    event_niches : [],
    period: null,
    event_themes:[],
    event_types : null,
    language: null,
    is_picked_from_pool:null
}

const EditorSlice = createSlice({
    name: 'EditorReducer',
    initialState : initalState,
    reducers :{
        setEditorState : (state,action: PayloadAction<IEditorAPIState>) => {
            state.bundle_version = action.payload.bundle_version
            state.created_at = action.payload.created_at
            state.data = action.payload.created_at
            state.document_id  = action.payload.document_id,
            state.event  =  action.payload.event
            state.status = action.payload.status,
            state.title =  action.payload.title
            state.token_url = action.payload.token_url
            state.websocket_url = action.payload.websocket_url
            state.track_changes = action.payload.track_changes
            state.reviewer_event_id = action.payload.reviewer_event_id,
            state.sources = action.payload.sources,
            state.isEpisode = action.payload.isEpisode,
            state.discarded_reason = action.payload.discarded_reason,
            state.date=action.payload.date,
            state.event_locations= action.payload.event_locations,
            state.tags= action.payload.tags,
            state.event_niches = action.payload.event_niches,
            state.period= action.payload.period,
            state.event_themes=action.payload.event_themes,
            state.event_types = action.payload.event_types,
            state.language= action.payload.language,
            state.is_picked_from_pool = action.payload.is_picked_from_pool
        }
    }
})

export const {setEditorState} = EditorSlice.actions;
export default EditorSlice.reducer 